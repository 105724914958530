@import '../variables';
@import '../mixin';

.mobile-header {
  position: relative;
  width: 100%;
  background: #fff;
  padding: 10px 0;
  z-index: 99;
  .icon {
    font-size: 1.4rem;
    &-menu {
      color: #5069aa;
      vertical-align: middle;
      margin-right: 10px;
      font-size: 1.4rem;
    }
  }
  .logo {
    vertical-align: middle;
    max-height: 45px;
  }
  .lang-box {
    text-transform: uppercase;
    font-size: 1.3rem;
    ul {
      list-style-type: none;
      padding: 0 10px;
      margin: 0;
      li a {
        display: block;
      }
    }
    .dropdown-toggle {
      padding: 5px;
      .lang {
        display: inline-block;
        padding: 0;
        li a {
          display: none;
          &.active {
            display: inline-block;
          }
        }
      }
      &:after {
        display: none;
      }
    }
    .dropdown-menu .lang {
      li {
        text-transform: uppercase;
        margin-bottom: 8px;
        a.active {
          display: none;
        }
      }
    }
  }
  .search-box {
    position: relative;
    padding: 5px 10px;
    .icon {
      color: #5069aa
    }
    .search-form {
      .form-control {
        border-radius: 0;
        height: 30px;
        border-right: none;
        font-size: .9rem;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
      .btn {
        height: 30px;
        border-radius: 5px;
        padding: 2px 5px;
        background: #5069aa;
        color: #fff;
        border-radius: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
    .box {
      display: none;
      position: absolute;
      top: 55px;
      right: -10px;
      width: 310px;
      z-index: 99;
      background: #fff;
      padding: 0 24px;
      border-radius: 3px;
    }
  }
  .card-box {
    position: relative;
    .icon {
      font-size: 1.6rem;
    }
    &__link {
      position: relative;
      display: inline-block;
      padding: 5px 10px;
    }
    &__count {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      font-size: .8rem;
      border-radius: 3px;
      background: red;
      color: #fff;
      font-weight: bold;
      padding: 4px 4px 1px 4px;
      line-height: 1;
    }
  }
}

.mobile-menu {
  position: fixed;
  display: block;
  width: 100%;
  max-width: 250px;
  background: #fff;
  height: 100%;
  top: 0;
  left: -300px;
  z-index: 9;
  padding: 10px 10px;
  .header {
    position: relative;
  }
  &.open {
    left: 0;
  }
  &__close {
    font-size: 1.2rem;
    display: inline-block;
    vertical-align: middle;
    border: none;
    background: #fff;
    padding: 8px;
    color: #5069aa;
  }
  .logo {
    vertical-align: middle;
    max-height: 35px;
  }
  .navbar-nav {
    padding: 0 5px;
  }
  .phone {
    margin-left: 30px;
  }
}

@media all and (max-width: 575px) {
  .mobile-header .search-box .box {
    right: -70px;
  }
}